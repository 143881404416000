* {
    margin: 0;
    padding: 0;
}

html,
body {
    overflow: hidden;
}

.webgl {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    outline: none;
    cursor: pointer;
}

.replay {
    position: absolute;
    z-index: 2;
    bottom: 2rem;
    right: 5rem;
    background-color: white;
    color: black;
    padding: 0.5rem 1rem;
    border-radius: 1000px;
    font-family: monospace;
    cursor: pointer;
    transition: transform 0.3s ease;
    animation: appear 0.7s ease-out 4s forwards;
    opacity: 0;
}

.v3 {
    position: absolute;
    z-index: 2;
    bottom: 2rem;
    right: 2rem;
    background-color: white;
    color: black;
    padding: 0.5rem;
    border-radius: 1000px;
    font-family: monospace;
    cursor: pointer;
    transition: transform 0.3s ease;
    animation: appear 0.7s ease-out 4s forwards;
    opacity: 0;
    text-decoration: none;
}


.replay:hover,
.v3:hover {
    transform: scale(1.05);
}

@keyframes appear {
    to {
        opacity: 1;
    }
}